.inputField.inputField {
  background-color: white;
  color: white;
  border: 0px;
  border-radius: 4px;
  margin-top: 12px;
}
.nxtBtn.nxtBtn {
  border-radius: 10px;
  background-color: white;
  color: #121212;
}
.nxtBtn.nxtBtn:hover {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}
