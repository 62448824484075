.uploadText {
  background-color: #121212;
  cursor: pointer;
  padding: 10px;
}
.uploadText::after {
  content: '*';
}
.uploadSRT {
  background-color: #121212;
  cursor: pointer;
  padding: 10px;
}
.nxtBtn.nxtBtn {
  border-radius: 10px;
  background-color: white;
  color: #121212;
  margin-bottom: 3.125rem;
}
.inputSrc.inputSrc {
  color: white;
}
.inputSrc.inputSrc {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}
.inputSrc.inputSrc::after {
  content: '*';
}
.selectLang.selectLang {
  width: 100%;
  min-width: 250px;
  color: rgb(253, 253, 253);
  background-color: rgb(84, 83, 83);
  border-radius: 10px;
  outline: none;
}
.nxtBtn.nxtBtn:hover {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}
