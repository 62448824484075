.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #edf0f2;
  margin: 0 auto;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
