.sideBarToggler.sideBarToggler {
  background-color: transparent;
  height: 25px;
  color: white;
}
.closeContainer {
  display: flex;
  height: 100vh;
  width: 10px;
  background-color: #222222;
  padding-top: 34px;
  color: rgb(255 255 255);
}
