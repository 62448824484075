.container {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    width: 50px;
    height: 50px;
  }
  .text {
    font-size: 12px;
    line-height: 18px;
  }
}
