.logoutBtn.logoutBtn {
  color: white;
  background-color: transparent;
  border: 0px;
  border-radius: 10px;
  height: 35px;
  width: fit-content;
}
.uploadText {
  background-color: #121212;
  cursor: pointer;
  padding: 10px;
}
.uploadText::after {
  content: '*';
}
.logoutBtn.logoutBtn:hover {
  color: black;
  background-color: white;
}
.header {
  position: absolute;
  text-align: center;
  left: 45%;
  right: 40%;
  font-weight: 500;
  font-size: 24px;
  color: rgb(255 255 255);
}
.navigationButton.navigationButton {
  width: 200px;
  border-radius: 10px;
  font-size: 12px;
  margin-bottom: 20px;
  color: white;
  border: 1px solid rgb(97, 96, 96);
  background-color: #343434;
}
.navigationButton.navigationButton:hover {
  background-color: #121212;
}
.currentButton.currentButton {
  width: 200px;
  border-radius: 10px;
  font-size: 12px;
  margin-bottom: 20px;
  color: white;
  border: 1px solid rgb(97, 96, 96);
  background-color: #171717;
}
.currentButton.currentButton:hover {
  background-color: #121212;
}
.status {
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 1500px) {
  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.layoutChildren {
  position: absolute;
  top: 100px;
  height: calc(100vh - 100px);
  width: 100vw;
  overflow: scroll;
  background-color: #191a1b;
  padding-left: 60px;
}
.Usr.Usr {
  color: white;
  font-size: small;
  background-color: #121212;
  border: 0px;
  border-radius: 10px;
  height: 35px;
  width: 150px;
  margin-bottom: 20px;
}

.newProject.newProject {
  color: white;

  background-color: transparent;
  border: 0px;
  border-radius: 10px;
  height: 35px;
  width: fit-content;
}
.newProject.newProject:hover {
  color: black;
  background-color: white;
}
